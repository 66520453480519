import React from 'react'

export const Close = ({t, ns}) => {
    return <svg
        role="img"
        className={ns('CloseIcon')}
        aria-label={t(['close'])}
        width="18"
        height="18"
        viewport="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{t(['close'])}</title>
        <line x1="1" y1="17" x2="17" y2="1" strokeWidth="1" />
        <line x1="1" y1="1" x2="17" y2="17" strokeWidth="1" />
    </svg>
}